import React, { useEffect } from 'react';
import Page from '../components/layouts/Page';
import Sidecard from '../components/molecules/Sidecard';
import FancyLink from '../components/molecules/FancyLink';
import ImageSlider from '../components/molecules/ImageSlider';
import CallToAction from '../components/CallToAction';
import WoodSpacer from '../components/molecules/WoodSpacer';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../components/molecules/Button';
import { useState } from 'react';

export default function contentSidebarCtaSlider({ data }) {
  const content = data.wpPage;
  const innerContent = data.wpPage.paginaTemplate.contentSidebarCtaSlider;

  const allJsonBreadcrumbs = [];
  const allBreadcrumbs = [];

  if (content.seo.breadcrumbs !== null) {
    content.seo.breadcrumbs.forEach((breadcrumb, index) => {
      allJsonBreadcrumbs.push({
        position: index + 1,
        name: breadcrumb.name,
        item: 'https://graafdaron.nl' + breadcrumb.item,
      });
      allBreadcrumbs.push({
        name: breadcrumb.name,
        item: breadcrumb.item,
      });
    });
  }

  const [formState, setFormState] = useState(null);
  const [errorState, setErrorState] = useState(null);

  useEffect(() => {
    const formSubmissionHandler = (event) => {
      event.preventDefault();

      setFormState('sending');

      const formElement = event.target,
        body = new FormData(formElement);

      const action =
        'https://rowanvanzijl.nl/blog/wp-json/contact-form-7/v1/contact-forms/1002/feedback';

      fetch(action, {
        method: 'POST',
        body,
      })
        .then((response) => response.json())
        .then((response) => {
          setFormState('sent');
          if (response.status !== 'mail_sent') {
            setFormState('error');
            setErrorState(response.message);
          }
          // Determine if the submission is not valid
          // Handle the happy path
        })
        .catch((error) => {
          setFormState('error');
          setErrorState(error);
        });
    };

    const formElement = document.querySelector('.wpcf7-form');

    formElement &&
      formElement.addEventListener('submit', formSubmissionHandler);
  });

  const getPageContent = () => {
    if (formState === null) {
      return innerContent.text;
    }
    if (formState === 'sending') {
      return `
        <div class="animate-pulse">
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-80 bg-slate-200 mt-5 rounded-md"></div>
        </div>`;
    }
    if (formState === 'sent') {
      return `<div class="h-30"> <p>Succesvol verzonden! We komen zo snel mogelijk bij je terug</p></div>
        <div class="opacity-0">
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-80 bg-slate-200 mt-5 rounded-md"></div>
        </div>`;
    }
    if (formState === 'error') {
      return `<div class="h-30"> <p>${errorState}</p></div>
        <a onclick='window.location.reload();
' style='text-decoration: none' class="bg-blue cursor-pointer contact-link shadow-md !text-white rounded-full px-7 py-3 font-body text-lg inline-block uppercase font-semibold my-5 group">Opnieuw proberen<svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-2.5 inline align-sub transition duration-200 group-hover:translate-x-2"><path d="M0.707607 18.5321L8.38858 10.8536L8.74231 10.5L8.38858 10.1464L0.707607 2.46789L2.47368 0.706223L12.292 10.5L2.47368 20.2938L0.707607 18.5321Z" fill="white" stroke="#DC2626"></path></svg></a>
        <div class="opacity-0">
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-10 bg-slate-200 mt-5 rounded-md"></div>
          <div class="h-80 bg-slate-200 mt-5 rounded-md"></div>
        </div>`;
    }
  };

  return (
    <>
      {content.seo.breadcrumbs ? (
        <>
          <GatsbySeo
            title={content.seo.seoData.title}
            description={content.seo.seoData.description}
            openGraph={{
              type: 'website',
              url: 'https://graafdaron.nl/' + content.slug,
              title: content.seo.seoData.title,
              description: content.seo.seoData.description,
            }}
          />
          <BreadcrumbJsonLd itemListElements={allJsonBreadcrumbs} />
        </>
      ) : (
        ''
      )}
      <Page
        breadcrumbs={
          <>
            <>
              {allBreadcrumbs.map((breadcrumb) => {
                return (
                  <Breadcrumb title={breadcrumb.name} link={breadcrumb.item} />
                );
              })}
            </>
          </>
        }
        sidebar={true}
        title={content.title}
        html={getPageContent()}
        aside={
          innerContent.sidebar &&
          innerContent.sidebar.map((bar) => {
            if (bar.contact) {
              return (
                <Sidecard title="Contactgegevens">
                  {data.wp?.aparteInstellingen.globalSettings.contactData.map(
                    (contact) => {
                      return (
                        <div className="mb-4">
                          <div className="flex items-center flex-nowrap">
                            <img
                              className="mr-3"
                              src={contact.icon.localFile.url}
                              alt="iconContact"
                            />
                            <a
                              href={contact.link.url}
                              className="underline font-body"
                            >
                              {contact.link.title}
                            </a>
                          </div>
                          {contact.subtext && (
                            <span className="block ml-8 text-sm font-body">
                              *{contact.subtext}
                            </span>
                          )}
                        </div>
                      );
                    },
                  )}
                </Sidecard>
              );
            } else if (bar.socialmedia) {
              return (
                <Sidecard title="Deel dit artikel">
                  <FancyLink
                    link={`https://www.facebook.com/sharer/sharer.php?u=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="Facebook"
                  />
                  <FancyLink
                    link={`https://twitter.com/intent/tweet?text=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="Twitter"
                  />
                  <FancyLink
                    link={`https://api.whatsapp.com/send?text=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="WhatsApp"
                  />
                </Sidecard>
              );
            } else {
              return (
                <Sidecard title={bar.sidebarItem.title}>
                  {bar.linksOrText
                    ? bar.sidebarItem.links.map((link) => {
                        return (
                          <FancyLink
                            link={link.link.url}
                            target={link.link.target}
                            title={link.link.title}
                          />
                        );
                      })
                    : bar.sidebarItem.text}
                </Sidecard>
              );
            }
          })
        }
        bottom={
          <>
            {innerContent.callToAction.type === 'customhtml' && (
              <>
                <CallToAction
                  style={innerContent.callToAction.type}
                  html={innerContent.callToAction.customHtml}
                />
                <WoodSpacer small={true} />
              </>
            )}
            {innerContent.callToAction.type !== 'customhtml' && (
              <>
                <ImageSlider />
                <CallToAction
                  style={innerContent.callToAction?.type ?? 'bigtower'}
                  html={innerContent.callToAction?.customHtml}
                  title={innerContent.callToAction?.title}
                  text={innerContent.callToAction?.text}
                  buttontext={innerContent.callToAction.button?.title}
                  buttonurl={innerContent.callToAction.button?.url}
                />
                <WoodSpacer small={true} />
              </>
            )}
          </>
        }
      />
    </>
  );
}

export const query = graphql`
  query contactContentSidebarCtaSliderQuery($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      slug
      paginaTemplate {
        contentSidebarCtaSlider {
          text
          sidebar {
            linksOrText
            sidebarItem {
              title
              text
              links {
                link {
                  title
                  url
                  target
                }
              }
            }
            contact
            socialmedia
          }
          callToAction {
            type
            text
            customHtml
            title
            button {
              target
              title
              url
            }
          }
        }
      }
      seo {
        seoData {
          description
          title
        }
        breadcrumbs {
          item
          name
        }
      }
    }
    wp {
      aparteInstellingen {
        globalSettings {
          contactData {
            subtext
            icon {
              localFile {
                url
              }
            }
            link {
              title
              url
            }
          }
        }
      }
    }
  }
`;
